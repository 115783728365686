<template>
  <div class="section page-box-edit-cust-address-model">
    <div class="page-title">
      {{ $route.params.custCn }}
    </div>
    <div class="page-input">
      <cube-form
        :model="model"
        class="form-wrapper"
        @submit="submitHandler"
      >
        <div class="form-content">
          <cube-form-group class="form-group">
            <cube-form-item
              :field="fields[0]"
              class="labelContent-column"
            >
              <div
                slot="label"
                class="customize-lable"
              >
                <span class="customize-lable-txt">所在地区</span>
                <i class="required-x">*</i>
              </div>
              <div
                class="field-wrapper label-column-text"
                @click="showAddressPicker"
              >
                <cube-input
                  v-model="model.address"
                  class="label-content"
                  :readonly="true"
                  placeholder="请选择"
                />
                <span
                  class="postion-text-icon"
                  @click.stop="adjustAddress"
                ><i class="icon-joyo arrows">&#xe72d;</i>定位</span>
              </div>
              <!-- <div class="border-bottom-1px  marginT"></div> -->
            </cube-form-item>
            <!-- 详细地址 -->
            <cube-form-item
              :field="fields[1]"
              class="labelContent-column"
            >
              <div
                slot="label"
                class="customize-lable"
              >
                <span class="customize-lable-txt">详细地址</span>
                <i class="required-x">*</i>
              </div>
              <div
                class="field-wrapper label-column-text"
              >
                <cube-input
                  v-model="model.addrStreet"
                  class="label-content"
                  placeholder="请选择"
                />
              </div>
              <!-- <div class="border-bottom-1px marginT"></div> -->
            </cube-form-item>
          </cube-form-group>
        </div>
        <div
          class="bottom"
        >
          <cube-button
            type="submit"
            class="btn btnWidth"
          >
            保存
          </cube-button>
        </div>
      </cube-form>
    </div>
  </div>

</template>

<script>
import { modifyAddress } from '@/api/sign';
import { areaList, cityList, provinceList } from '@/common/data/area';

const addressData = provinceList;
addressData.forEach(province => {
  province.children = cityList[province.value];
  province.children.forEach(city => {
    city.children = areaList[city.value];
  });
});

export default {
  name: 'EditCustAddress',
  data() {
    return {
      model: {
        address: '', // 地址拼接的数据
        addrProvince: '', // 省code
        addrCity: '', // 城市code
        addrArea: '', // 区code
        province: '', // 省name
        city: '', // 城市name
        area: '', // 区name
        addrStreet: ''
      },
      fields: [
        // 0
        {
          type: 'input',
          modelKey: 'address',
          label: '客户地址',
          props: { placeholder: '请选择', readonly: true },
          rules: { required: true }
        },
        // 1
        {
          type: 'textarea',
          modelKey: 'addrStreet',
          label: '详细地址',
          props: { placeholder: '最多输入50个汉字', max: 50, maxlength: 50 },
          rules: { required: true }
        }
      ],
      addressPicker: null
    }
  },
  mounted() {
    this.syncModels();
    this.createAddressPicker();
  },
  methods: {
    // vueX赋值
    syncModels() {
      // console.log(this.$store.state.sign)
      const signState = this.$store.state.sign;
      this.model.address = (signState.custAddress.province ? signState.custAddress.province : '') + (signState.custAddress.city ? signState.custAddress.city : '') + (signState.custAddress.area ? signState.custAddress.area : '');
      this.model.province = signState.custAddress.province; // 省name
      this.model.city = signState.custAddress.city; // 城市name
      this.model.area = signState.custAddress.area; // 区name
      this.model.addrProvince = signState.custAddress.addrProvince; // 省
      this.model.addrCity = signState.custAddress.addrCity; // 城市
      this.model.addrArea = signState.custAddress.addrArea; // 区
      this.model.addrStreet = signState.custAddress.addrStreet; // 实际地址
    },
    // 初始化地址选择器
    createAddressPicker() {
      this.addressPicker = this.$createCascadePicker({
        title: '请选择地址',
        data: addressData,
        onSelect: this.selectAddressHandle
      });
    },
    showAddressPicker() {
      this.addressPicker.show();
    },
    selectAddressHandle(selectedVal, selectedIndex, selectedText) {
      this.model.address = selectedText.join(''); // 回显的
      this.model.province = selectedText[0]; // 省name
      this.model.city = selectedText[1]; // 城市name
      this.model.area = selectedText[2]; // 区name
      // code
      this.model.addrProvince = selectedVal[0] || '';
      this.model.addrCity = selectedVal[1] || '';
      this.model.addrArea = selectedVal[2] || '';
    },
    // 跳转地址微调页面
    adjustAddress() {
      this.$router.push('/position-address');
    },
    submitHandler(e) {
      e.returnValue = false;
      const params = {
        id: this.$route.params.id,
        ...this.model
      }
      modifyAddress(params).then(res => {
        if (res.flag) {
          this.$showToast('保存成功');
          this.$router.replace({ name: 'select-customer', query: { index: this.$route.params.index }})
        } else {
          this.$showToast(res.errorMsg);
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss">
  .page-box-edit-cust-address-model{
    .cube-input-field{
      padding: 0 !important;
    }
    .cube-input{
      color: #23252E;
      font-size: 16px;
      line-height: 20px;
    }
    .cube-form-field{
      width: 100%;
    }
  }
</style>

<style lang="scss" scoped>
.page-title {
  margin: 48px auto;
  margin-bottom: 24px;
  color: #23252E;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.page-input{
  // margin: 0 15px 46px 15px;
}
.form-content{
  margin:0 0 48px 15px;
}

// 部分内容接口样式
.labelContent-column{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px 0;
  border-bottom: 1PX solid #ebebeb;
}
.customize-lable{
  width: 110px;
  color: #5A5C66;
  font-size: 13px;
  text-align: left;
  .customize-lable-txt{
    display:inline-block;
    margin-bottom: 8px;
  }
}
.label-column-text {
  display: flex;
  margin-right: 15px;
}
.required-x{
  margin-left: 2px;
  color: #FF585C;
}
.label-content{
  flex:1;
}
.postion-text-icon{
  width: 50px;
  color: #999BA3;
  font-size: 16px;
  line-height: 20px;
}
.marginT{
  margin-top: 18px;
}
</style>

<style lang="scss">
.page-box-edit-cust-address-model{
  .cube-form-item{
    padding-left: 0;
  }
  .border-bottom-1px::after{
    border: 0;
  }
}
</style>
